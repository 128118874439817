<template>
    <div class="product-section pt-5">
			<div class="container">
				<div class="row">

					<div class="col-12 mb-5">
						<h2 class="mb-4 section-title">Мебель Vivat</h2>
						<p class="mb-4">Выберите категорию</p>
					</div> 

					<div class="col-12 col-md-4 col-lg-3 mb-5" v-for="category in categories" :key="category.id">
						<a :href="'/mebelVivat/'+category.id" class="product-item">
							<img :src="category.pictures" class="img-fluid product-thumbnail">
							<h3 class="product-title">{{ category.title }}</h3>
						</a>
					</div>

				</div>
			</div>
		</div>
</template>

<script>
export default{
    data(){
        return{
            categories: []
        }
    },
    beforeMount(){
        let mebelJSON = this.$store.state.mebelJSON

        for(let i=0; i<mebelJSON['categories'].length; i++){
            if(mebelJSON['categories'][i]['pictures'].length == 0){
                mebelJSON['categories'][i]['pictures'] = '#'
            }
            mebelJSON['categories'][i]['pictures'] = Object.values(mebelJSON['categories'][i]['pictures'][0])[0]
            this.categories.push(mebelJSON['categories'][i])
        }
    }
}
</script>