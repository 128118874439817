<template>
    <div class="product-section pt-5">
			<div class="container">
				<div class="row">

					<div class="col-12 mb-5">
						<h2 class="mb-4 section-title">Каталог дверей VFD</h2>
						<p class="mb-4">Выберите категорию</p>
                        <p><a href="/doors/vfd/all" class="btn">Все двери</a></p>
					</div>

					<div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/vfd/category_1" >
                            <img src="../assets/images/vfd_1.png" class="img-fluid product-thumbnail">
							<h3 class="product-title">Межкомнатные двери</h3>
						</a>
					</div>

                    <div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/vfd/category_2" >
                            <img src="../assets/images/vfd_2.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Порталы</h3>
						</a>
					</div>

                    <div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/vfd/category_3" >
                            <img src="../assets/images/vfd_3.png" class="img-fluid product-thumbnail">
							<h3 class="product-title">Фурнитура и прочее</h3>
						</a>
					</div>

				</div>
			</div>
		</div>
</template>