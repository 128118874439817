<template>
    <main-menu />
    <div class="hero">
		<div class="container">
			<div class="row">
				<div class="col-lg-5">
					<div class="intro-excerpt">
						<h1 class="mb-0">Портфолио</h1>
					</div>
				</div>
			</div>
            <div class="row">
                <div class="col-lg-3">
                    <div class="subscription-form mt-1 mt-lg-3">
                        <form action="#" class="row g-3">
                            <div class="col-12">
                                <input type="text" class="form-control" placeholder="Введите ФИО" v-model="clientName">
                            </div>
                            <div class="col-12">
                                <input type="phone" class="form-control" placeholder="Введите номер телефона" v-model="clientPhone">
                            </div>
                        </form>
                        <button class="btn btn-secondary mt-3 mb-lg-0 mb-5" @click="order">Оставить заявку</button>
                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <div class="container">
    <div class="product-section">
		    <div class="row">
		      	<!-- Start Column 1 -->
				<div class="col-6 col-md-4 col-lg-3 mb-5" v-for="(product, index) in portfolio" :key="index">
					<div class="product-item">
						<img :src="product.img" class="img-fluid product-thumbnail">
						<h3 class="product-title">{{product.title}}</h3>
						<p class="product-description">{{ product.description }}</p>
                    </div>
				</div> 
				<!-- End Column 1 -->
		    </div>
		</div>
        </div>
    <main-footer />
</template>

<script>
import MainMenu from '../components/MainMenu.vue'
import MainFooter from '../components/MainFooter.vue'
import axios from 'axios'
export default{
    components: {
		MainMenu, MainFooter
	},
    data(){
        return{
            clientName: '',
            clientPhone: '',
            portfolio: this.$store.state.portfolio
        }
    },
    methods: {
        sendTGMessage(message){
                const telegramBotToken = '6964611268:AAFod5LCm9ClvVKeXW4foV17kOVaR4yXYMI';
                const chatId = '@V5kNvnEdwkgwMWQy';
                let data = {
                    chat_id: chatId,
                    text: message
                }
                axios.post(`https://api.telegram.org/bot${telegramBotToken}/sendMessage`, data)
        },

        order(){
            if(this.clientName !== '' && this.clientPhone.length >10){
                console.log(this.clientName)
                console.log(this.clientPhone)
                let data = 
                        {
                            name: this.clientName,
                            phone: this.clientPhone,
                            furniture: true
                        }

                axios.post('https://всевдом.рф/api.php', data)
                .then(() =>{
                    this.sendTGMessage(`ЗАЯВКА МЕБЕЛЬ!\n${this.clientName}\n${this.clientPhone}`)
                    alert('Заявка успешно отправлена')
                    this.clientName = ''
                    this.clientPhone = ''
                })
                .catch(function (error) {
                    alert('Произошла ошибка')
                    console.log(error);
                });
        }
        else{
          alert('Заявка не может быть отправлена, проверьте данные')
        }
        }
    }
}
</script>