import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import catalog from '../assets/rez2.json'
import mebel from '../assets/mebel.json'
//0-1236 1237-2598 2599-2758 2759-3075

export default createStore({
  state: {
    shoppingCart: [],
    total: 0,
    json: catalog,
    mebelJSON: mebel,
    portfolio: [
      {
        img: '/portfolio/prod1.jpg',
        title: 'Стол письменный',
        description: 'Массив дуба, металлокаркас'
      },
      {
        img: '/portfolio/prod2.jpg',
        title: 'Шкаф распашной в прихожую',
        description: 'Мдф эмаль матовая, фрезеровка на заказ'
      },
      {
        img: '/portfolio/prod3.jpg',
        title: 'Интерьерные панели',
        description: 'Лдсп Эггер'
      },
      {
        img: '/portfolio/prod4.jpg',
        title: 'Полка для кубков в хокейный клуб Спартак',
        description: 'Мдф эмаль глянец'
      },
      {
        img: '/portfolio/prod5.jpg',
        title: 'Ресепшен в х.к. Спартак',
        description: 'Мдф эмаль глянец'
      },
      {
        img: '/portfolio/prod6.jpg',
        title: 'Шкаф под лесничным маршем',
        description: 'Массив ясеня'
      },
      {
        img: '/portfolio/prod7.jpg',
        title: 'Шкаф купе',
        description: 'Мдф эмаль матовая, выполнен согласно дизайн проекту'
      },
      {
        img: '/portfolio/prod8.jpg',
        title: 'Рабочая зона в детской',
        description: 'Мдф эмаль'
      },
      {
        img: '/portfolio/prod9.jpg',
        title: 'Комод - туалетный столик',
        description: 'Мдф эмаль, согласно дизайн проекту'
      },
      {
        img: '/portfolio/prod10.jpg',
        title: 'ТВ тумба',
        description: 'Мдф эмаль, согласно дизайн проекту'
      },
      {
        img: '/portfolio/prod11.jpg',
        title: 'Интерьерные серванты',
        description: 'Массив ясеня, согласно дизайн проекту'
      },
      {
        img: '/portfolio/prod12.jpg',
        title: 'Рабочий кабинет',
        description: 'Согласно дизайн проекту'
      },
      {
        img: '/portfolio/prod13.jpg',
        title: 'Шкаф прихожая',
        description: 'Мдф пвх шпон, согласно дизайн проекту'
      },
      {
        img: '/portfolio/prod14.jpg',
        title: 'Гардеробная комната',
        description: ''
      },
      {
        img: '/portfolio/prod15.jpg',
        title: 'Гардеробная комната',
        description: ''
      },
      {
        img: '/portfolio/prod16.jpg',
        title: 'Место игрока',
        description: 'Хоккейный клуб Спартак'
      },
      {
        img: '/portfolio/prod17.jpg',
        title: 'Стол подоконник',
        description: 'Массив дуба'
      },
      {
        img: '/portfolio/prod18.jpg',
        title: 'Шкаф распашной',
        description: 'Мдф эмаль, согласно дизайн проекту'
      },
      {
        img: '/portfolio/prod19.jpg',
        title: 'Сан. Узел',
        description: 'Мдф пвх шпон, согласно дизайн проекту'
      },
      {
        img: '/portfolio/prod20.jpg',
        title: 'Шкаф распашной в детскую',
        description: 'Согласно дизайн проекту'
      },
      {
        img: '/portfolio/prod21.jpg',
        title: 'Шкаф распашной в прихожую',
        description: 'Согласно дизайн проекту'
      },
      {
        img: '/portfolio/prod22.jpg',
        title: 'Шкаф распашной в прихожую',
        description: 'Мдф эмаль матовая, шпон дуба, согласно дизайн проекту'
      },
      {
        img: '/portfolio/prod23.jpg',
        title: 'ТВ тумба с туалетным столиком',
        description: 'Согласно дизайн проекту'
      },
      {
        img: '/portfolio/prod24.jpg',
        title: 'Кровать массив дуба',
        description: 'Согласно дизайн проекту'
      },
      {
        img: '/portfolio/prod25.jpg',
        title: 'Шкаф распашной',
        description: 'Мдф эмаль, согласно дизайн проекту'
      },
      {
        img: '/portfolio/prod26.jpg',
        title: 'Стол письменный',
        description: 'Шпон ясеня, согласно дизайн проекту'
      },
      {
        img: '/portfolio/prod27.jpg',
        title: 'Кафе',
        description: 'Мдф эмаль, массив дуба, согласно дизайн проекту'
      },
      {
        img: '/portfolio/prod28.jpg',
        title: 'Шкафы для коньков',
        description: 'Массив дуба, зона Проката ярмарки Московские сезоны'
      },
      {
        img: '/portfolio/prod29.jpg',
        title: 'Кухонный гарнитур',
        description: 'Массив ольха, эмаль глянец, согласно дизайн проекту'
      },
      {
        img: '/portfolio/prod30.jpg',
        title: 'Кухонный гарнитур',
        description: 'Мдф эмаль глянец, согласно дизайн проекту'
      },
      {
        img: '/portfolio/prod31.jpg',
        title: 'Тумба в сан.узле',
        description: 'Мдф эмаль, массив дуба, согласно дизайн проекту'
      },
      {
        img: '/portfolio/prod32.jpg',
        title: 'Кухонный гарнитур',
        description: 'Эмаль, шпон дуба, латунь'
      },
      {
        img: '/portfolio/prod33.jpg',
        title: 'Кухонный гарнитур',
        description: 'Мдф эмаль глянец, согласно дизайн проекту'
      },
      {
        img: '/portfolio/prod34.jpg',
        title: 'Кухонный гарнитур',
        description: 'Мдф эмаль, шпон, согласно дизайн проекту'
      }
    ]
  },
  getters: {
  },
  mutations: {
    addToCart(state, elem){
      state.shoppingCart.push(elem);
      this.commit('countTotal');
    },
    deleteFromCart(state, elem){
      state.shoppingCart.splice(elem, 1);
      this.commit('countTotal')
    },
    increaseQuantity(state, elem){
      state.shoppingCart[elem]['quantity']++;
      this.commit('countTotal')
    },
    decreaseQuantity(state, elem){
      state.shoppingCart[elem]['quantity']--;
      this.commit('countTotal');
    },
    resetCart(state){
      state.shoppingCart = [];
      state.total = 0;
    },
    countTotal(state){
      state.total = 0;
      state.shoppingCart.forEach(elem => {
        state.total += elem.price * elem.quantity;
      });
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      paths: ['shoppingCart', 'total']
  })
  ]
})