<template>
    <div class="product-section pt-5">
			<div class="container">
				<div class="row">

					<div class="col-12 mb-5">
						<h2 class="mb-4 section-title">Каталог дверей Bravo</h2>
						<p class="mb-4">Выберите категорию</p>
                        <p><a href="/doors/bravo/all" class="btn">Все двери</a></p>
					</div>

					<div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/bravo/category_1" >
                            <img src="../assets/images/bravo_1.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Межкомнатные двери</h3>
						</a>
					</div>

                    <div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/bravo/category_2" >
                            <img src="../assets/images/bravo_2.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Входные двери</h3>
						</a>
					</div>

                    <div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/bravo/category_3" >
                            <img src="../assets/images/bravo_3.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Складные двери</h3>
						</a>
					</div>

                    <div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/bravo/category_4" >
                            <img src="../assets/images/bravo_4.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Скрытые двери</h3>
						</a>
					</div>

                    <div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/bravo/category_5" >
                            <img src="../assets/images/bravo_5.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Специальные двери</h3>
						</a>
					</div>

                    <div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/bravo/category_6" >
                            <img src="../assets/images/bravo_6.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Арки и порталы</h3>
						</a>
					</div>

                    <div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/bravo/category_7" >
                            <img src="../assets/images/bravo_7.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Фурнитура и прочее</h3>
						</a>
					</div>

				</div>
			</div>
		</div>
</template>