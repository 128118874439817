<template>
    <main-menu />
        <div class="product-section pt-5">
			<div class="container">
				<div class="row">

					<div class="col-12 mb-5">
						<h2 class="mb-4 section-title">Каталог дверей</h2>
						<p class="mb-4">Выберите фабрику</p>
                        <p><a href="/doors/all" class="btn mb-5">Полный каталог</a></p>
					</div> 

					<div class="col-12 col-md-4 col-lg-3 mb-5">
						<a href="/doors/bravo" class="product-item">
							<img src="../assets/images/bravo_logo.svg" class="img-fluid product-thumbnail" style="height: 100px; max-width: 50%;">
							<h3 class="product-title">Двери Bravo</h3>
						</a>
					</div>

                    <div class="col-12 col-md-4 col-lg-3 mb-5">
						<a href="/doors/vfd" class="product-item">
							<img src="../assets/images/vfd_logo.svg" class="img-fluid product-thumbnail" style="height: 100px; max-width: 50%;">
							<h3 class="product-title">Двери VFD</h3>
						</a>
					</div>

                    <div class="col-12 col-md-4 col-lg-3 mb-5">
						<a href="/doors/legenda" class="product-item">
							<img src="../assets/images/legenda_logo.png" class="img-fluid product-thumbnail" style="height: 100px; max-width: 50%;">
							<h3 class="product-title">Двери Legenda</h3>
						</a>
					</div>

                    <div class="col-12 col-md-4 col-lg-3 mb-5">
						<a href="/doors/verda" class="product-item">
							<img src="../assets/images/verda_logo.svg" class="img-fluid product-thumbnail" style="height: 100px; max-width: 50%;">
							<h3 class="product-title">Двери Verda</h3>
						</a>
					</div>

				</div>
			</div>
		</div>
    <main-footer />
</template>

<script>
import MainMenu from '../components/MainMenu.vue'
import MainFooter from '../components/MainFooter.vue'

export default{
    components: {
        MainMenu, MainFooter
    }
}
</script>