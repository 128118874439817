<template>
    <div class="product-section pt-5">
			<div class="container">
				<div class="row">

					<div class="col-12 mb-5">
						<h2 class="mb-4 section-title">Каталог дверей Legenda</h2>
						<p class="mb-4">Выберите категорию</p>
                        <p><a href="/doors/legenda/all" class="btn">Все двери</a></p>
					</div>

					<div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/legenda/category_1" >
                            <img src="../assets/images/legenda_1.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Экошпон</h3>
						</a>
					</div>

					<div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/legenda/category_2" >
                            <img src="../assets/images/legenda_2.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Шпон</h3>
						</a>
					</div>

					<div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/legenda/category_3" >
                            <img src="../assets/images/legenda_3.png" class="img-fluid product-thumbnail">
							<h3 class="product-title">Под покраску</h3>
						</a>
					</div>
				</div>
			</div>
		</div>
</template>