<template>
<!-- Start Footer Section -->
<footer class="footer-section">
    <div class="container relative">

        <div class="row">
            <div class="col-lg-8">
                <div class="subscription-form mt-0 mt-lg-5">
                    <h3 class="d-flex align-items-center"><span class="me-1"><img src="../assets/images/envelope-outline.svg" alt="Image" class="img-fluid"></span><span>Получить консультацию</span></h3>

                    <form action="#" class="row g-3">
                        <div class="col-sm-auto col-12">
                            <input type="text" class="form-control" placeholder="Введите ФИО" v-model="helpName">
                        </div>
                        <div class="col-sm-auto col-12">
                            <input type="phone" class="form-control" placeholder="Введите номер телефона" v-model="helpPhone">
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-primary" @click="sendHelp">
                                Отправить
                            </button>
                        </div>
                    </form>

                </div>
            </div>
        </div>

        <div class="row g-5 mb-5">
            <div class="col-lg-4">
                <div class="mb-4 footer-logo-wrap"><a href="#" class="footer-logo"><img src="../assets/images/logo_vsevdom.png" class="me-1" style="width: 1em; height: 1em; margin-bottom: 0.2em;" alt="">Все в Дом<span>.</span>рф</a></div>
                <p class="mb-4">У нас на сайте вы можете выбрать двери или мебель по душе. Мы предоставляем огромный выбор из тысяч позиций, поддержку профессионалов, быструю доставку и удобный каталог</p>

                <!-- <ul class="list-unstyled custom-social">
                    <li><a href="#"><span class="fa fa-brands fa-facebook-f"></span></a></li>
                    <li><a href="#"><span class="fa fa-brands fa-twitter"></span></a></li>
                    <li><a href="#"><span class="fa fa-brands fa-instagram"></span></a></li>
                    <li><a href="#"><span class="fa fa-brands fa-linkedin"></span></a></li>
                </ul> -->
            </div>

            <div class="col-lg-8">
                <div class="row links-wrap">
                    <div class="col-6 col-sm-6 col-md-3">
                        <ul class="list-unstyled">
                            <li><a href="/#">Главная</a></li>
                            <li><a href="/#about">О нас</a></li>
                            <li><a href="/#catalog">Каталог</a></li>
                            <li><a href="/#contacts">Контакты</a></li>
                        </ul>
                    </div>
                    
                    <div class="col-6 col-sm-6 col-md-3">
                        <ul class="list-unstyled">
                            <li><a href="/#contacts">Поддержка</a></li>
                            <li><a href="/#contacts">Консультация</a></li>
                            <li><a href="/политика_конфиденциальности.pdf">Политика конфиденциальности</a></li>
                        </ul>
                    </div>

                    <div class="col-6 col-sm-6 col-md-3">
                        <ul class="list-unstyled">
                            <li><a href="/#catalog">Каталог</a></li>
                            <li><a href="/doors">Двери</a></li>
                            <li><a href="/#furniture">Мебель</a></li>
                        </ul>
                    </div>

                    <div class="col-6 col-sm-6 col-md-3">
                        <ul class="list-unstyled">
                            <li><a href="tel:+79672525797">+7 (967) 252 57 97</a></li>
                            <li><a href="tel:+79654151125">+7 (965) 415 11 25</a></li>
                            <li><a href="mailto:anviga.info@mail.ru">anviga.info@mail.ru</a></li>
                            <li>143988, г. Балашиха,<br> д. Павлинод. 66, пом. 50</li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>

        <div class="border-top copyright">
            <div class="row pt-4">
                <div class="col-lg-6">
                    <p class="mb-2 text-center text-lg-start">&copy;ООО «Стройдоминженеринг» &mdash; Двери и мебель для вашего дома
    </p>
                </div>

                <div class="col-lg-6 text-center text-lg-end">
                    <ul class="list-unstyled d-sm-inline-flex ms-auto">
                        <li><a href="/information.pdf">Информация о предприятии</a></li>
                        <li><a href="/политика_конфиденциальности.pdf" class="ms-sm-3">Политика конфиденциальности</a></li>
                    </ul>
                </div>

            </div>
        </div>

    </div>
</footer>
<!-- End Footer Section -->	
</template>

<script>
import axios from 'axios'
export default{
    data(){
        return{
            helpName: '',
            helpPhone: ''
        }
    },
    methods: {
        sendTGMessage(message){
                const telegramBotToken = '6964611268:AAFod5LCm9ClvVKeXW4foV17kOVaR4yXYMI';
                const chatId = '@V5kNvnEdwkgwMWQy';
                let data = {
                    chat_id: chatId,
                    text: message
                }
                axios.post(`https://api.telegram.org/bot${telegramBotToken}/sendMessage`, data)
        },
        sendHelp(){
            if(this.helpName !== '' && this.helpPhone.length >10){
                console.log(this.helpName)
                console.log(this.helpPhone)

                let data = 
                {
                    name: this.helpName,
                    phone: this.helpPhone
                }

                axios.post('https://всевдом.рф/api.php', data)
                .then(() =>{
                    this.sendTGMessage(`ЗАЯВКА КОНСУЛЬТАЦИЯ!\n${this.helpName}\n${this.helpPhone}`)
                    this.helpName = ''
                    this.helpPhone = ''
                    alert('Заявка успешно отправлена')
                })
                .catch(function (error) {
                    alert('Произошла ошибка')
                    console.log(error);
                });
                }
            else{
                alert('Заявка не может быть оформлена, проверьте данные')
            }
        }
    }
}
</script>