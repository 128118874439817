<template>
    <!-- Start Header/Navigation -->
		<nav class="custom-navbar navbar navbar navbar-expand-md navbar-dark bg-dark" arial-label="Furni navigation bar">

<div class="container">
     <a class="navbar-brand" href="/"><img src="../assets/images/logo_vsevdom_circle.png" class="me-1" style="width: 1.8em; height: 1.8em; margin-bottom: 0.2em;" alt="">Все в Дом<span>.</span>рф</a>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsFurni" aria-controls="navbarsFurni" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarsFurni">
        <ul class="custom-navbar-nav navbar-nav ms-auto mb-2 mb-md-0">
            <li class="nav-item">
                <a class="nav-link" href="/#">Главная</a>
            </li>
            <li><a class="nav-link" href="/#about">О нас</a></li>
            <!-- <li><a class="nav-link" href="/#">Каталог</a></li> -->
            <li><a class="nav-link" href="/doors">Двери</a></li>
            <li><a class="nav-link" href="/#furniture">Мебель</a></li> 
            <!-- <li><a class="nav-link" href="#">Отзывы</a></li> -->
            <li><a class="nav-link" href="/#contacts">Контакты</a></li>
        </ul>

        <ul class="custom-navbar-cta navbar-nav mb-2 mb-md-0 ms-5">
            <li><a class="nav-link" href="/cart"><img src="../assets/images/cart.svg"> <span class="ms-1">Корзина</span></a></li>
        </ul>
    </div>
</div>
    
</nav>
<!-- End Header/Navigation -->
</template>