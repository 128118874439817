<template>
    <!-- Product Modal Window-->
    <div class="modal fade" id="productModal" tabindex="-1" aria-labelledby="productModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="productModalLabel">{{productCard.title}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <img :src="productCard.img" class="img-fluid w-50 h-50 productCardImg" style="margin-left: 25%;">
        <div class="productCardDescription mt-5"></div>
        <div class="full-price mt-3" style="font-weight: 600; font-size: 1.2em;">Цена за комплект: {{ productCard['full_price'] }}₽</div>
      </div>
      <div class="modal-footer">
            <span class="me-3">{{ productCard.price }}₽</span>
            <a type="button" v-if="inCart" class="btn btn-primary" href="/cart">Перейти в корзину</a>
            <button v-else type="button" class="btn btn-primary" @click="addToCart(productCard)">В корзину</button>
      </div>
    </div>
  </div>
</div>

    <!-- Start Hero Section -->
			<div class="hero">
				<div class="container">
					<div class="row justify-content-between">
						<div class="col-12">
							<div class="intro-excerpt">
								<h1 class="mb-0">{{ pageTitle }}</h1>
							</div>
						</div>
					</div>
                    <div class="row">
                        <div class="col-12 filter-form"> <!--сортировка, кол-во на странице, категория, диапазон цены, поиск-->
                            <p style="color: #fff; font-size: 1.2em; cursor: pointer; opacity: 0.8; text-decoration: underline;" class="d-block d-md-none" @click="controlParams">{{ paramsText }}</p>
                            <div class="d-lg-flex d-block w-100 justify-content-between mt-3">
                                <div class="params" :style="{display: displayParams}">
                            <select class="form-select form-control me-3 mb-2" style="width: fit-content;" @change="catalogSort(sortVal)" v-model="sortVal">
                                <option selected>Сортировать</option>
                                <!-- <option value="defaultSort">По умолчанию</option> -->
                                <option value="up">По возрастанию цены</option>
                                <option value="down">По убыванию цены</option>
                            </select>
                            <select class="form-select form-control me-3 mb-2" style="width: fit-content;" v-model="pageNum" @change="countPages(); catalogShow(0);">
                                <option selected>Отображать по</option>
                                <option value="20">20</option>
                                <option value="60">60</option>
                                <option value="120">120</option>
                            </select>
                            </div>
                            <input type="text" class="form-control w-25 d-none d-md-block" placeholder="Найти товар" v-model="searchVal" @keyup.enter="catalogSearch">
                            <input type="text" class="form-control w-100 mb-3 mt-5 d-block d-md-none" placeholder="Найти товар" v-model="searchVal" @keyup.enter="catalogSearch">
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		<!-- End Hero Section -->

		<div class="untree_co-section product-section before-footer-section">
		    <div class="container">
		      	<div class="row">

		      		<!-- Start Column 1 -->
					<div class="col-6 col-md-4 col-lg-3 mb-5" v-for="(product, index) in doors" :key="index">
						<a class="product-item" data-bs-toggle="modal" data-bs-target="#productModal" @click="showProductCard(product)">
							<img :src="product.img" class="img-fluid product-thumbnail">
							<h3 class="product-title">{{product.title}}</h3>
							<strong class="product-price">₽{{ product.price }}</strong>
						</a>
					</div> 
					<!-- End Column 1 -->
		      	</div>
		    </div>
            
            <div class=" w-auto container">
                <div class="d-flex flex-column" style="width: fit-content;">
                    <p class="ms-2" style="font-size: 1.2em;">Страницы</p>
                    <div class="container">
                        <button class="m-2" :id="'page'+page" style="background-color: transparent; border: none; font-size: 1.2em;" v-for="page in pages" @click="catalogShow(page-1)">{{ page }}</button>
                    </div>
                </div>
            </div>
                
		</div>

</template>

<script>
export default{
    props: {
        pageTitle: String, //заголовок страницы
        searchCategory: String, //текст категории
        startNum: Number, //начало выборки из общего массива
        endNum: Number //конец выборки в общем массиве + 1
    },

  data(){
    return{
		result: [], //массив всех товаров
		doors: [], //товары на одной странице
        displayParams: 'flex', //показ блока параметров
        paramsText: 'открыть параметры', //кнопка для параметров
        windowWidth: window.innerWidth, //ширина экрана
        pages: 0, //количество страниц
        productCard: {}, //объект для карточки товара
        shoppingCart: this.$store.state.shoppingCart, //корзина
        inCart: false, //флаг для определения товаров в корзине
        sortVal: 'Сортировать', //параметр сортировки
        pageNum: 20, //параметр кол-ва товаров на странице
        categoryChecked: this.category, //параметр выбранных категорий
        catalogFiltered: [], //массив товаров с выбранной категорией
        searchVal: '', //значение поиска
        catalogSearched: []
    }
  },

  methods: {
    catalogShow(n){ //вывод товаров на странице
        this.doors = [] //обнуление массива товаров на странице
        if(this.searchVal.length==0){
            let end = n*Number(this.pageNum)+Number(this.pageNum); //задаем конец цикла, равный параметру кол-ва товаров на странице
            if(n == this.pages-1) end = n*Number(this.pageNum)+this.result['dor'].length%Number(this.pageNum) //кол-во товаров на последней странице
        
            for(let i=n*Number(this.pageNum); i<end; i++){ //заполнение массива doors
            this.doors.push({
                img: this.result['dor'][i]['picture_link'],
                title: this.result['dor'][i].name,
                price: this.result['dor'][i]['kit_price'],
                full_price: this.result['dor'][i]['price'],
                description: this.result['dor'][i].description
            })
        }
        }
        else{
            this.pages = 1
            for(let i=0; i<this.catalogSearched.length; i++){ //заполнение массива doors
                this.doors.push({
                    img: this.catalogSearched[i]['picture_link'],
                    title: this.catalogSearched[i].name,
                    price: this.catalogSearched[i]['kit_price'],
                    full_price: this.catalogSearched[i]['price'],
                    description: this.catalogSearched[i].description
                })
            }
        }
    },

    catalogSearch(){
        this.catalogSearched = []
        for(let i=0; i<this.result['dor'].length; i++){
            if(this.result['dor'][i]['name'].toLowerCase().indexOf(this.searchVal.toLowerCase()) > -1){
                this.catalogSearched.push(this.result['dor'][i])
            }
        }
        if(this.catalogSearched.length == 0) alert('Товар не найден')
        console.log(this.catalogSearched)
        this.catalogSort(this.sortVal)
        this.catalogShow(0)
    },

    controlParams(){ //открытие списка параметров
        if(this.displayParams == 'none'){
            this.displayParams = 'block'
            this.paramsText = 'закрыть параметры'
        }
        else{
            this.displayParams = 'none'
            this.paramsText = 'открыть параметры'
        }
    },

    countPages(){ //подсчет кол-ва страниц
        let x = parseInt(this.result['dor'].length/Number(this.pageNum)) //целочисленное кол-во страниц
        let y = 0
        if (this.result['dor'].length%Number(this.pageNum)) y = 1 //последняя страница
        this.pages = x+y
        
    },

    showProductCard(prod){ //заполнение данных для карточки товара
        this.productCard = prod;

        this.$store.state.shoppingCart.forEach(elem => { //определение, есть ли товар в корзине
            if(elem.title === prod.title){
                this.inCart = true
            }
            else{
                this.inCart = false
            }
        });

        document.getElementsByClassName('productCardDescription')[0].innerHTML = this.productCard.description; //добавление описания товара как html кода
    },

    addToCart(prod){ //добавление в корзину
        prod['quantity'] = 1;
        this.$store.commit('addToCart', prod)
        this.inCart = true
    },

    catalogSort(type){ //сортировка по цене
        let sortedArr = [] //массив для сортировки
        this.catalogFiltered.length == 0 ? sortedArr = this.result['dor'] : sortedArr = this.catalogFiltered //копируем в sortedArr один из массивов в зависимости, есть ли выбранные категории
        switch(type){
            case 'up':
                sortedArr.sort(function(a, b){ //сортировка по возрастанию
                    if (Number(a['kit_price']) < Number(b['kit_price'])){
                        return -1;
                    }
                    if(Number(a['kit_price']) > Number(b['kit_price'])){
                        return 1;
                    }
                    return 0;
                })
                this.catalogShow(0) //вывод обновленного списка товаров на странице
                break;

            case 'down':
                sortedArr.sort(function(a, b){ //сортировка по убыванию
                    if (Number(a['kit_price']) > Number(b['kit_price'])){
                        return -1;
                    }
                    if(Number(a['kit_price']) < Number(b['kit_price'])){
                        return 1;
                    }
                    return 0;
                })
                this.catalogShow(0) //вывод обновленного списка товаров на странице
                break;

            case 'defaultSort':
                location.reload(); //перезагрузка страницы
                break;
        }
        
    }
  },
  beforeMount(){
    if(this.windowWidth <=768){ //скрытие параметров (фильтра) в моб версии
        this.displayParams = 'none'
    }

    this.result['dor'] = [];
 
    for(let i=this.startNum; i<this.endNum; i++){
        if(this.$store.state.json['dor'][i]['name'].toLowerCase().indexOf(this.searchCategory.toLocaleLowerCase()) > -1){
            this.result['dor'].push(this.$store.state.json['dor'][i])
        }
    }//заполнение массива товаров из vuex

    console.log(this.result['dor'])
    this.countPages() //подсчет кол-ва страниц

    this.catalogShow(0) //первое отображение товаров при открытии страницы

    for(let i=0; i<this.result['dor'].length; i++){
        if(this.result['dor'][i]['kit_price']==''){
            this.result['dor'][i]['kit_price'] = this.result['dor'][i]['price']
        }
    };
  }
}
</script>

<style lang="scss">
.modal-footer{
    span{
        font-size: 1.5em;
        color: #000;
        margin-top: auto;
        margin-bottom: auto;
        font-weight: 600;
    }
}

.filterCategories{
    width: 25vw;
}

@media screen and (max-width: 992px){
.filterCategories{
    width: 30vw;
}
}

@media screen and (max-width: 576px){
.filterCategories{
    width: 80vw;
}
}
</style>