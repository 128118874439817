<template>
<main-menu />

    <!-- Start Hero Section -->
			<div class="hero">
				<div class="container">
					<div class="row justify-content-between">
						<div class="col-lg-5">
							<div class="intro-excerpt">
								<h1>Корзина</h1>
							</div>
						</div>
						<div class="col-lg-7">
							
						</div>
					</div>
				</div>
			</div>
		<!-- End Hero Section -->

		

		<div class="untree_co-section before-footer-section">
            <div class="container">
              <div class="row mb-5">
                <form class="col-md-12" method="post">
                  <div class="site-blocks-table">
                    <table class="table">
                      <tbody class="d-none d-sm-block">
                        <tr v-for="(item, index) in shoppingCart" :key="index">
                          <td class="product-thumbnail">
                            <img :src="item.img" alt="Image" class="img-fluid cart-img-desk">
                          </td>
                          <td class="product-name">
                            <h2 class="h5 text-black">{{ item.title }}</h2>
                          </td>
                          <td>
                            <div class="input-group d-flex align-items-center quantity-container" style="max-width: 120px;">
                              <div class="input-group-prepend">
                                <button class="btn btn-outline-black decrease" @click="decreaseQuantity(index)" type="button">&minus;</button>
                              </div>
                              <input type="text" class="form-control text-center quantity-amount" v-model="item.quantity" placeholder="" aria-label="Example text with button addon" aria-describedby="button-addon1">
                              <div class="input-group-append">
                                <button class="btn btn-outline-black increase" @click="increaseQuantity(index);" type="button">&plus;</button>
                              </div>
                            </div>
        
                          </td>
                          <td style="font-size: 1.35em;">₽{{ item.price * item.quantity }}</td>
                          <td><a @click="deleteItem(index)" class="btn btn-black btn-sm">X</a></td>
                        </tr>
                        <h1 v-if="shoppingCart.length === 0">Нет товаров в корзине</h1>
                      </tbody>

                      <tbody class="d-block d-sm-none">
                        <div v-for="(item, index) in shoppingCart" :key="index">
                        <tr style="border-bottom-width: 0;">
                          <td class="product-thumbnail">
                            <img :src="item.img" alt="Image" class="img-fluid cart-img-mob">
                          </td>
                          <td class="product-name" style="width: 60%;">
                            <h2 class="h5 text-black">{{ item.title }}</h2>
                          </td>
                        </tr>
                        <tr style="padding-top: 0;">
                          <td style="padding-top: 0;">
                            <div class="input-group d-flex align-items-center quantity-container" style="max-width: 120px;">
                              <div class="input-group-prepend">
                                <button class="btn btn-outline-black decrease" @click="decreaseQuantity(index)" type="button">&minus;</button>
                              </div>
                              <input type="text" class="form-control text-center quantity-amount" v-model="item.quantity" placeholder="" aria-label="Example text with button addon" aria-describedby="button-addon1">
                              <div class="input-group-append">
                                <button class="btn btn-outline-black increase" @click="increaseQuantity(index);" type="button">&plus;</button>
                              </div>
                            </div>
        
                          </td>
                          <td style="font-size: 1.5em; padding-top: 0;">₽{{ item.price * item.quantity }}</td>
                          <!-- <td><a href="#" class="btn btn-black btn-sm">X</a></td> -->
                        </tr>
                        </div>
                        <h1 v-if="shoppingCart.length === 0">Нет товаров в корзине</h1>
                      </tbody>
                    </table>
                  </div>
                </form>
              </div>
        
              <div class="row">
                <div class="col-md-6">
                  <div class="row mb-5">
                    <div class="col-md-6 mb-3 mb-md-0">
                      <button class="btn btn-black btn-sm btn-block" @click="resetCart">Очистить корзину</button>
                    </div>
                    <div class="col-md-6">
                      <button class="btn btn-outline-black btn-sm btn-block" @click="$router.go(-1)">Продолжить покупки</button>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 pl-5">
                  <div class="row justify-content-end">
                    <div class="col-md-7">
                      <div class="row">
                        <div class="col-md-12 text-right border-bottom mb-3">
                          <h3 class="text-black h4 text-uppercase">Итого</h3>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col-6">
                          <span class="text-black" style="font-size: 1.5em;">Сумма</span>
                        </div>
                        <div class="col-6 text-end">
                          <strong class="text-black" style="font-size: 1.5em;">₽{{ $store.state.total }}</strong>
                        </div>
                      </div>
                      <div class="subscription-form mt-2 mt-lg-3">
                        <form action="#" class="row g-3">
                            <div class="col-12">
                                <input type="text" class="form-control" placeholder="Введите ФИО" v-model="clientName">
                            </div>
                            <div class="col-12">
                                <input type="phone" class="form-control" placeholder="Введите номер телефона" v-model="clientPhone">
                            </div>
                        </form>

                    </div>
                      <div class="row">
                        <div class="col-md-12">
                          <button class="btn btn-black btn-sm py-3 mt-3 btn-block" @click="order">Оформить заказ</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

<main-footer />
</template>

<script>
import MainMenu from '../components/MainMenu.vue'
import MainFooter from '../components/MainFooter.vue'
import axios from 'axios'

export default{
    components: {
        MainMenu, MainFooter
    },
    data(){
      return{
        shoppingCart: this.$store.state.shoppingCart,
        clientName: '',
        clientPhone: ''
      }
    },
    methods: {
      sendTGMessage(message){
          const telegramBotToken = '6964611268:AAFod5LCm9ClvVKeXW4foV17kOVaR4yXYMI';
          const chatId = '@V5kNvnEdwkgwMWQy';
          let data = {
            chat_id: chatId,
            text: message
          }
        axios.post(`https://api.telegram.org/bot${telegramBotToken}/sendMessage`, data)
      },

      decreaseQuantity(index){
        if(this.shoppingCart[index].quantity === 1){
          this.$store.commit('deleteFromCart', index);
        }
        else{
          this.$store.commit('decreaseQuantity', index)
        }
      },

      increaseQuantity(index){
        this.$store.commit('increaseQuantity', index)
        console.log(this.$store.state.total)
      },

      deleteItem(index){
        this.$store.commit('deleteFromCart', index);
      },

      resetCart(){
        this.$store.commit('resetCart')
        this.shoppingCart = []
      },

      order(){
        if(this.clientName !== '' && this.clientPhone.length >10 && this.shoppingCart.length > 0){
          console.log(this.clientName)
          console.log(this.clientPhone)
          console.log(this.shoppingCart)
          let data = 
                {
                    name: this.clientName,
                    phone: this.clientPhone,
                    shoppingCart: this.shoppingCart
                }

                axios.post('https://всевдом.рф/api.php', data)
                .then(() =>{
                    let cartMessage = ''
                    for(let i=0; i<this.shoppingCart.length; i++){
                      cartMessage += `\n${this.shoppingCart[i]['title']} ${this.shoppingCart[i]['quantity']}шт - ${this.shoppingCart[i]['price']*this.shoppingCart[i]['quantity']} руб.`
                    }

                    this.sendTGMessage(`НОВЫЙ ЗАКАЗ\n\n ${this.clientName}\n${this.clientPhone}\n\nКОРЗИНА${cartMessage}\n\nИтого: ${this.$store.state.total} руб.`)
                    alert('Заявка успешно отправлена')
                    this.clientName = ''
                    this.clientPhone = ''
                    this.$store.commit('resetCart')
                    this.shoppingCart = []
                })
                .catch(function (error) {
                    alert('Произошла ошибка')
                    console.log(error);
                });
        }
        else{
          alert('Заказ не может быть оформлен, проверьте данные')
        }
      }
    }
}
</script>

<style lang="scss">
.cart-img-mob{
  height: 10%;
}

.cart-img-desk{
  height: 100px;
}
</style>