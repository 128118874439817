<template>
    <div class="product-section pt-5">
			<div class="container">
				<div class="row">

					<div class="col-12 mb-5">
						<h2 class="mb-4 section-title">Каталог дверей Verda</h2>
						<p class="mb-4">Выберите категорию</p>
                        <p><a href="/doors/verda/all" class="btn">Все двери</a></p>
					</div>

					<div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/verda/category_1" >
                            <img src="../assets/images/verda_1.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Межкомнатные двери Эмалит</h3>
						</a>
					</div>

                    <div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/verda/category_2" >
                            <img src="../assets/images/verda_2.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Межкомнатные двери Экошпон</h3>
						</a>
					</div>

                    <div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/verda/category_3" >
                            <img src="../assets/images/verda_3.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Межкомнатные двери Велюкс</h3>
						</a>
					</div>

                    <div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/verda/category_4" >
                            <img src="../assets/images/verda_4.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Межкомнатные двери Касапорте</h3>
						</a>
					</div>

                    <div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/verda/category_5" >
                            <img src="../assets/images/verda_5.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Межкомнатные двери Лофт</h3>
						</a>
					</div>

                    <div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/verda/category_6" >
                            <img src="../assets/images/verda_6.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Межкомнатные двери Турин</h3>
						</a>
					</div>

                    <div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/verda/category_7" >
                            <img src="../assets/images/verda_7.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Межкомнатные двери Next</h3>
						</a>
					</div>

                    <div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/verda/category_8" >
                            <img src="../assets/images/verda_8.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Межкомнатные двери Бавария</h3>
						</a>
					</div>

                    <div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/verda/category_9" >
                            <img src="../assets/images/verda_9.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Межкомнатные двери Геометрия</h3>
						</a>
					</div>

                    <div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/verda/category_10" >
                            <img src="../assets/images/verda_10.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Межкомнатные двери Матрикс</h3>
						</a>
					</div>

                    <div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/verda/category_11" >
                            <img src="../assets/images/verda_11.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Межкомнатные двери Свит 3D</h3>
						</a>
					</div>

                    <div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/verda/category_12" >
                            <img src="../assets/images/verda_12.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Межкомнатные двери Эльдорф</h3>
						</a>
					</div>

                    <div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/verda/category_13" >
                            <img src="../assets/images/verda_13.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Межкомнатные двери Роял</h3>
						</a>
					</div>

                    <div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/verda/category_14" >
                            <img src="../assets/images/verda_14.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Межкомнатные двери Винил</h3>
						</a>
					</div>

                    <div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/verda/category_15" >
                            <img src="../assets/images/verda_15.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Межкомнатные двери Аляска</h3>
						</a>
					</div>

                    <div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/verda/category_16" >
                            <img src="../assets/images/verda_16.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Межкомнатные двери Эмаль</h3>
						</a>
					</div>

                    <div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/verda/category_17" >
                            <img src="../assets/images/verda_17.png" class="img-fluid product-thumbnail">
							<h3 class="product-title">Межкомнатные двери Шпон</h3>
						</a>
					</div>

                    <div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/verda/category_18" >
                            <img src="../assets/images/verda_18.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Межкомнатные двери Складные</h3>
						</a>
					</div>

                    <div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/verda/category_19" >
                            <img src="../assets/images/verda_19.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Межкомнатные двери Облицованные</h3>
						</a>
					</div>

                    <div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/verda/category_20" >
                            <img src="../assets/images/verda_20.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Входные двери Металлические</h3>
						</a>
					</div>

                    <div class="col-6 col-md-4 col-lg-3 mb-5">
						<a class="product-item" href="/doors/verda/category_21" >
                            <img src="../assets/images/verda_21.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Фурнитура и прочее</h3>
						</a>
					</div>
				</div>
			</div>
		</div>
</template>