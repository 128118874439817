<template>
    <main-menu />
	<router-view />
    <main-footer />
</template>

<script>
import MainMenu from '../components/MainMenu.vue'
import MainFooter from '../components/MainFooter.vue'

export default{
    components: {
        MainMenu, MainFooter
    }
}
</script>