<template>
	<main-menu />
	<div class="modal fade" id="productModal" tabindex="-1" aria-labelledby="productModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="productModalLabel">{{productCard.title}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <img :src="productCard.img" class="img-fluid w-50 h-50 productCardImg" style="margin-left: 25%;">
        <div class="productCardDescription mt-5"></div>
		<div class="full-price mt-3" style="font-weight: 600; font-size: 1.2em;">Цена за комплект: {{ productCard['full_price'] }}₽</div>
      </div>
      <div class="modal-footer">
            <span class="me-3">{{ productCard.price }}₽</span>
            <a type="button" v-if="inCart" class="btn btn-primary" href="/cart">Перейти в корзину</a>
            <button v-else type="button" class="btn btn-primary" @click="addToCart(productCard)">В корзину</button>
      </div>
    </div>
  </div>
</div>

		<!-- Start Hero Section -->
			<div class="hero">
				<div class="container">
					<div class="row justify-content-between">
						<div class="col-lg-5">
							<div class="intro-excerpt">
								<h1>Двери и мебель <span clsas="d-block">в ваш дом</span></h1>
								<p class="mb-4">Предлагаем большой каталог мебели и дверей для вашего дома. Смотрите полный ассортимент на нашем сайте.</p>
								<p><a href="/doors" class="btn btn-secondary me-2">Двери</a><a href="/#furniture" class="btn btn-white-outline">Мебель</a></p>
							</div>
						</div>
						<div class="col-lg-7">
							<div class="hero-img-wrap mb-5" style="margin-top: 50px;">
								<!-- <img src="../assets/images/main_door.png" class="img-fluid"> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		<!-- End Hero Section -->
		<a name="catalog"></a>
		<!-- Start Product Section 1-->
		<div class="product-section pt-5">
			<div class="container">
				<div class="row">

					<!-- Start Column 1 -->
					<div class="col-md-12 col-lg-3 mb-5 mb-lg-0">
						<h2 class="mb-4 section-title">Каталог дверей</h2>
						<p class="mb-4">Полный каталог дверей, порталов, аксессуаров. Мы предлагаем для вас выбор из более чем 3000 позиций.</p>
						<p><a href="/doors" class="btn">Перейти</a></p>
					</div> 
					<!-- End Column 1 -->

					<!-- Start Column 2 -->
					<div class="col-12 col-md-4 col-lg-3 mb-5">
						<a href="/doors/bravo" class="product-item">
							<img src="../assets/images/bravo_logo.svg" class="img-fluid product-thumbnail" style="height: 100px; max-width: 50%;">
							<h3 class="product-title">Двери Bravo</h3>
						</a>
					</div>

                    <div class="col-12 col-md-4 col-lg-3 mb-5">
						<a href="/doors/vfd" class="product-item">
							<img src="../assets/images/vfd_logo.svg" class="img-fluid product-thumbnail" style="height: 100px; max-width: 50%;">
							<h3 class="product-title">Двери VFD</h3>
						</a>
					</div>

					<div class="col-12 col-md-4 col-lg-3 mb-5">
						<a href="/doors/verda" class="product-item">
							<img src="../assets/images/verda_logo.svg" class="img-fluid product-thumbnail" style="height: 100px; max-width: 50%;">
							<h3 class="product-title">Двери Verda</h3>
						</a>
					</div>
					<!-- End Column 2 -->

				</div>
			</div>
		</div>
		<!-- End Product Section 1-->
		<a name="furniture"></a>
    <!-- Start Product Section 2-->
		<div class="product-section product-section-2 pt-5">
			<div class="container">
				<div class="row">

					<!-- Start Column 1 -->
					<div class="col-md-12 col-lg-3 mb-5 mb-lg-0">
						<h2 class="mb-4 section-title">Каталог мебели</h2>
						<p class="mb-4">Полный каталог мебели от наших партнеров в широком ассортименте и мебель на заказ на нашем сайте </p>
						<!-- <p><a href="#" class="btn">Перейти</a></p> -->
					</div> 
					<!-- End Column 1 -->

					<!-- Start Column 2 -->
					<div class="col-12 col-md-4 col-lg-3 mb-5 mb-md-0">
						<a class="product-item" href="/mebelVivat">
							<img src="../assets/images/vivat.webp" class="img-fluid product-thumbnail">
							<h3 class="product-title">Мебель Vivat</h3>
							<strong class="product-price"></strong>
						</a>
					</div> 
					<!-- End Column 2 -->

					<!-- Start Column 3 -->
					<div class="col-12 col-md-4 col-lg-3 mb-5 mb-md-0">
						<a class="product-item" href="/furniture">
							<img src="/portfolio/prod32.jpg" class="img-fluid product-thumbnail">
							<h3 class="product-title">Мебель на заказ</h3>
							<strong class="product-price"></strong>
						</a>
					</div>
					<!-- End Column 3 -->

				</div>
			</div>
		</div>
		<!-- End Product Section 2-->
		<a name="about"></a>
		<!-- Start Why Choose Us Section -->
		<div class="why-choose-section pt-5">
			<div class="container">
				<div class="row justify-content-between">
					<div class="col-lg-6">
						<h2 class="section-title">Почему стоит выбрать нас</h2>
						<p>Личное пространство оберегает каждый человек, межкомнатные и входные двери - надежный защитник Вашего личного пространства!</p>

						<div class="row my-5">
							<div class="col-6 col-md-6">
								<div class="feature">
									<div class="icon">
										<img src="../assets/images/truck.svg" alt="Image" class="imf-fluid">
									</div>
									<h3>Быстрая &amp; Удобная Доставка</h3>
									<p>Доставка до двери в кратчайшие сроки от наших проверенных дилеров</p>
								</div>
							</div>

							<div class="col-6 col-md-6">
								<div class="feature">
									<div class="icon">
										<img src="../assets/images/bag.svg" alt="Image" class="imf-fluid">
									</div>
									<h3>Большой выбор</h3>
									<p>Широкий ассортимент насчитывает более 3000 позиций товаров на сайте</p>
								</div>
							</div>

							<div class="col-6 col-md-6">
								<div class="feature">
									<div class="icon">
										<img src="../assets/images/support.svg" alt="Image" class="imf-fluid">
									</div>
									<h3>Консультации профессионалов</h3>
									<p>На любом этапе заказа наши операторы готовы проконсультировать вас по любому вопросу</p>
								</div>
							</div>

							<div class="col-6 col-md-6">
								<div class="feature">
									<div class="icon">
										<img src="../assets/images/return.svg" alt="Image" class="imf-fluid">
									</div>
									<h3>Гарантия</h3>
									<p>Ваша защита от неудачных покупок предоставляется гарантией наших надежных дилеров</p>
								</div>
							</div>

						</div>
					</div>

					<div class="col-lg-5">
						<div class="img-wrap">
							<img src="../assets/images/why-choose-us-img.jpg" alt="Image" class="img-fluid">
						</div>
					</div>

				</div>
			</div>
		</div>
		<!-- End Why Choose Us Section -->

		<!-- Start We Help Section -->
		<div class="we-help-section">
			<div class="container">
				<div class="row justify-content-between">
					<div class="col-lg-7 mb-5 mb-lg-0">
						<div class="imgs-grid">
							<div class="grid grid-1"><img src="../assets/images/img-grid-1.jpg" alt="Untree.co"></div>
							<div class="grid grid-2"><img src="../assets/images/img-grid-2.jpg" alt="Untree.co"></div>
							<div class="grid grid-3"><img src="../assets/images/img-grid-3.jpg" alt="Untree.co"></div>
						</div>
					</div>
					<div class="col-lg-5 ps-lg-5">
						<h2 class="section-title mb-4">Помогаем вам создать современный интерьер</h2>
						<p>У нас на сайте вы можете выбрать двери или мебель по душе. Мы предоставляем огромный выбор из тысяч позиций, поддержку профессионалов и удобный каталог с фильтрами для вашего комфорта</p>

						<ul class="list-unstyled custom-list my-4">
							<li>Широкий ассортимент из тысяч товаров</li>
							<li>Удобный каталог с фильтрами</li>
							<li>Служба поддержки, готовая прийти на помощь</li>
							<li>Быстрая доставка и простой процесс заказа</li>
						</ul>
						<p><a href="/#catalog" class="btn">Каталог</a></p>
					</div>
				</div>
			</div>
		</div>
		<!-- End We Help Section -->
<a name="contacts"></a>
<main-footer />
		
</template>

<script>
import MainMenu from '../components/MainMenu.vue'
import MainFooter from '../components/MainFooter.vue'

export default{
  data(){
    return{
		result: this.$store.state.json,
		mainDoors: [],
		productCard: {}, //объект для карточки товара
        shoppingCart: this.$store.state.shoppingCart, //корзина
        inCart: false //флаг для определения товаров в корзине
    }
	},
	components: {
		MainMenu, MainFooter
	},
	methods: {
	showProductCard(prod){ //заполнение данных для карточки товара
        this.productCard = prod;

        this.$store.state.shoppingCart.forEach(elem => { //определение, есть ли товар в корзине
            if(elem.title === prod.title){
                this.inCart = true
            }
            else{
                this.inCart = false
            }
        });

        document.getElementsByClassName('productCardDescription')[0].innerHTML = this.productCard.description; //добавление описания товара как html кода
    },

    addToCart(prod){ //добавление в корзину
        prod['quantity'] = 1;
        this.$store.commit('addToCart', prod)
        this.inCart = true
    }
	},
	beforeMount(){
		let mebel = this.$store.state.mebelJSON

  }
}
</script>