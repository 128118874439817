import { createRouter, createWebHistory } from 'vue-router'
import MainPage from '../views/MainPage.vue'
import DoorsPage from '../views/DoorsPage.vue'
import CartPage from '../views/CartPage.vue'
import FurniturePage from '../views/FurniturePage.vue'
import DoorsCategoryPage from '../views/DoorsCategoryPage.vue'
import AllDoorsPage from '../views/AllDoorsPage.vue'
import CategoryListBravo from '../components/CategoryListBravo.vue'
import CatalogListCategory from '../components/CatalogListCategory.vue'
import AllCategoryList from '../components/AllCategoryList.vue'
import CategoryListVFD from '../components/CategoryListVFD'
import CategoryListLegenda from '../components/CategoryListLegenda.vue'
import CategoryListVerda from '../components/CategoryListVerda.vue'
import MebelPage from '../views/MebelPage.vue'
import MebelCategories from '../components/MebelCategories.vue'
import CategoryMebel from '../components/CategoryMebel.vue'
import CatalogListCategoryLegenda from '../components/CatalogListCategoryLegenda.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: MainPage
  },
  {
    path: '/doors',
    name: 'doors',
    component: DoorsPage,
  },
  {
    path: '/doors/all',
    name: 'doorsAll',
    component: AllDoorsPage,
  },
  {
    path: '/doors/bravo',
    name: 'doorsBravo',
    component: DoorsCategoryPage,
    children: [
      {  
        path: '',
        component: CategoryListBravo
      },
      {
        path: 'all',
        name: 'doorsBravoAll',
        component: AllCategoryList,
        props: {
          'page-title': 'Все двери BRAVO',
          'start-num': 0,
          'end-num': 1237
        }
      },
      {
        path: 'category_1',
        component: CatalogListCategory,
        props: {
          'page-title': 'Межкомнатные двери BRAVO',
          category: ['Межкомнатные двери'],
          'start-num': 0,
          'end-num': 1237
        }
      },
      {
        path: 'category_2',
        component: CatalogListCategory,
        props: {
          'page-title': 'Входные двери BRAVO',
          category: ['Входные двери'],
          'start-num': 0,
          'end-num': 1237
        }
      },
      {
        path: 'category_3',
        component: CatalogListCategory,
        props: {
          'page-title': 'Складные двери BRAVO',
          category: ['Складные двери'],
          'start-num': 0,
          'end-num': 1237
        }
      },
      {
        path: 'category_4',
        component: CatalogListCategory,
        props: {
          'page-title': 'Скрытые двери BRAVO',
          category: ['Скрытые двери'],
          'start-num': 0,
          'end-num': 1237
        }
      },
      {
        path: 'category_5',
        component: CatalogListCategory,
        props: {
          'page-title': 'Специальные двери BRAVO',
          category: ['Специальные двери'],
          'start-num': 0,
          'end-num': 1237
        }
      },
      {
        path: 'category_6',
        component: CatalogListCategory,
        props: {
          'page-title': 'Арки и порталы BRAVO',
          category: ['Арки и порталы'],
          'start-num': 0,
          'end-num': 1237
        }
      },
      {
        path: 'category_7',
        component: CatalogListCategory,
        props: {
          'page-title': 'Фурнитура и прочее BRAVO',
          category: ['Плинтус', 'Дело рейка', 'Фурнитура и прочее', 'Монтаж и реставрация'],
          'start-num': 0,
          'end-num': 1237
        }
      }
    ]
  },
  {
    path: '/doors/vfd',
    name: 'doorsVFD',
    component: DoorsCategoryPage,
    children: [
      {  
        path: '',
        component: CategoryListVFD
      },
      {
        path: 'all',
        name: 'doorsVFDAll',
        component: AllCategoryList,
        props: {
          'page-title': 'Все двери VFD',
          'start-num': 1237,
          'end-num': 2599
        }
      },
      {
        path: 'category_1',
        component: CatalogListCategory,
        props: {
          'page-title': 'Межкомнатные двери VFD',
          category: ['Межкомнатные двери'],
          'start-num': 1237,
          'end-num': 2599
        }
      },
      {
        path: 'category_2',
        component: CatalogListCategory,
        props: {
          'page-title': 'Порталы VFD',
          category: ['Порталы'],
          'start-num': 1237,
          'end-num': 2599
        }
      },
      {
        path: 'category_3',
        component: CatalogListCategory,
        props: {
          'page-title': 'Фурнитура и прочее VFD',
          category: ['Плинтус', 'Фурнитура'],
          'start-num': 1237,
          'end-num': 2599
        }
      }
    ]
  },
  {
    path: '/doors/legenda',
    name: 'doorsLegenda',
    component: DoorsCategoryPage,
    children: [
      {  
        path: '',
        component: CategoryListLegenda 
      },
      {
        path: 'all',
        name: 'doorsLegendaAll',
        component: AllCategoryList,
        props: {
          'page-title': 'Все двери Legenda',
          'start-num': 2599,
          'end-num': 2759
        }
      },
      {
        path: 'category_1',
        component: CatalogListCategoryLegenda,
        props: {
          'page-title': 'Двери Экошпон Legenda',
          searchCategory: 'Экошпон',
          'start-num': 2599,
          'end-num': 2759
        }
      },
      {
        path: 'category_2',
        component: CatalogListCategoryLegenda,
        props: {
          'page-title': 'Двери Шпон Legenda',
          searchCategory: 'Шпон',
          'start-num': 2599,
          'end-num': 2759
        }
      },
      {
        path: 'category_3',
        component: CatalogListCategoryLegenda,
        props: {
          'page-title': 'Двери под покраску Legenda',
          searchCategory: 'под покраску',
          'start-num': 2599,
          'end-num': 2759
        }
      }
    ]
  },
  {
    path: '/doors/verda',
    name: 'doorsVerda',
    component: DoorsCategoryPage,
    children: [
      {  
        path: '',
        component: CategoryListVerda
      },
      {
        path: 'all',
        name: 'doorsVFDAll',
        component: AllCategoryList,
        props: {
          'page-title': 'Все двери Verda',
          'start-num': 2759,
          'end-num': 3076
        }
      },
      {
        path: 'category_1',
        component: CatalogListCategory,
        props: {
          'page-title': 'Межкомнатные двери Verda Эмалит',
          category: ['эмалит Классика', 'эмалит Севилья'],
          'start-num': 2759,
          'end-num': 3076
        }
      },
      {
        path: 'category_2',
        component: CatalogListCategory,
        props: {
          'page-title': 'Межкомнатные двери Verda Экошпон',
          category: ['экошпон Премиум', 'экошпон Стандарт'],
          'start-num': 2759,
          'end-num': 3076
        }
      },
      {
        path: 'category_3',
        component: CatalogListCategory,
        props: {
          'page-title': 'Межкомнатные двери Verda Велюкс',
          category: ['Велюкс'],
          'start-num': 2759,
          'end-num': 3076
        }
      },
      {
        path: 'category_4',
        component: CatalogListCategory,
        props: {
          'page-title': 'Межкомнатные двери Verda Касапорте',
          category: ['Касапорте'],
          'start-num': 2759,
          'end-num': 3076
        }
      },
      {
        path: 'category_5',
        component: CatalogListCategory,
        props: {
          'page-title': 'Межкомнатные двери Verda Лофт',
          category: ['Двери серии Лофт. Доставка по всей Москве, установка, замер.'],
          'start-num': 2759,
          'end-num': 3076
        }
      },
      {
        path: 'category_6',
        component: CatalogListCategory,
        props: {
          'page-title': 'Межкомнатные двери Verda Турин',
          category: ['Турин'],
          'start-num': 2759,
          'end-num': 3076
        }
      },
      {
        path: 'category_7',
        component: CatalogListCategory,
        props: {
          'page-title': 'Межкомнатные двери Verda Next',
          category: ['Next'],
          'start-num': 2759,
          'end-num': 3076
        }
      },
      {
        path: 'category_8',
        component: CatalogListCategory,
        props: {
          'page-title': 'Межкомнатные двери Verda Бавария',
          category: ['Бавария 3D', 'Бавария ПВХ'],
          'start-num': 2759,
          'end-num': 3076
        }
      },
      {
        path: 'category_9',
        component: CatalogListCategory,
        props: {
          'page-title': 'Межкомнатные двери Verda Геометрия',
          category: ['Геометрия'],
          'start-num': 2759,
          'end-num': 3076
        }
      },
      {
        path: 'category_10',
        component: CatalogListCategory,
        props: {
          'page-title': 'Межкомнатные двери Verda Матрикс',
          category: ['Матрикс'],
          'start-num': 2759,
          'end-num': 3076
        }
      },
      {
        path: 'category_11',
        component: CatalogListCategory,
        props: {
          'page-title': 'Межкомнатные двери Verda Свит 3D',
          category: ['Свит 3D'],
          'start-num': 2759,
          'end-num': 3076
        }
      },
      {
        path: 'category_12',
        component: CatalogListCategory,
        props: {
          'page-title': 'Межкомнатные двери Verda Эльдорф',
          category: ['Эльдорф 3D', 'Эльдорф ЭКО'],
          'start-num': 2759,
          'end-num': 3076
        }
      },
      {
        path: 'category_13',
        component: CatalogListCategory,
        props: {
          'page-title': 'Межкомнатные двери Verda Роял',
          category: ['Роял'],
          'start-num': 2759,
          'end-num': 3076
        }
      },
      {
        path: 'category_14',
        component: CatalogListCategory,
        props: {
          'page-title': 'Межкомнатные двери Verda Винил',
          category: ['Винил', 'Винил бархат'],
          'start-num': 2759,
          'end-num': 3076
        }
      },
      {
        path: 'category_15',
        component: CatalogListCategory,
        props: {
          'page-title': 'Межкомнатные двери Verda Аляска',
          category: ['Аляска (Ренолит)'],
          'start-num': 2759,
          'end-num': 3076
        }
      },
      {
        path: 'category_16',
        component: CatalogListCategory,
        props: {
          'page-title': 'Межкомнатные двери Verda Эмаль',
          category: ['Эмаль, серия Багет', 'Эмаль, серия Граффити и 3D', 'Эмаль, серия Классика', 'Эмаль, серия Неоклассика', 'Эмаль, серия с патиной'],
          'start-num': 2759,
          'end-num': 3076
        }
      },
      {
        path: 'category_17',
        component: CatalogListCategory,
        props: {
          'page-title': 'Межкомнатные двери Verda Шпон',
          category: ['шпон Premium', 'шпон Комфорт', 'шпон Стандарт', 'шпон Элит'],
          'start-num': 2759,
          'end-num': 3076
        }
      },
      {
        path: 'category_18',
        component: CatalogListCategory,
        props: {
          'page-title': 'Межкомнатные двери Verda Складные',
          category: ['Двери складные'],
          'start-num': 2759,
          'end-num': 3076
        }
      },
      {
        path: 'category_19',
        component: CatalogListCategory,
        props: {
          'page-title': 'Межкомнатные двери Verda Облицованные',
          category: ['облицованные ПВХ', 'облицованные финиш-пленкой'],
          'start-num': 2759,
          'end-num': 3076
        }
      },
      {
        path: 'category_20',
        component: CatalogListCategory,
        props: {
          'page-title': 'Входные двери Verda Металлические',
          category: ['Металлические двери ВМ', 'Металлические двери SD Prof', 'Металлические двери SD-PROF Premium', 'Металлические двери Эконом'],
          'start-num': 2759,
          'end-num': 3076
        }
      },
      {
        path: 'category_21',
        component: CatalogListCategory,
        props: { 
          'page-title': 'Фурнитура и прочее Verda',
          category: ['Дополнительные комплектующие', 'Замки', 'Ключевина', 'Петли', 'Ручка-защелка', 'Ручки нажимные', 'Ручки нажимные Morelli', 'Ручки нажимные Palidore', 'Ручки нажимные Rucetti', 'Ручки нажимные Vantage', 'Фиксатор'],
          'start-num': 2759,
          'end-num': 3076
        }
      },
    ]
  },
  {
    path: '/cart',
    name: 'cart',
    component: CartPage
  },
  {
    path: '/furniture',
    name: 'furniture',
    component: FurniturePage
  },
  {
    path: '/mebelVivat',
    name: 'mebelVivat',
    component: MebelPage,
    children: [
      {
        path: '',
        component: MebelCategories
      },
      {
        path: ':category_id',
        component: CategoryMebel,
        props: true
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router
